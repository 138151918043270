<template>
  <vx-card id="head_card" :title="title">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="my-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Back</vs-button
        >
      </div>
    </div>
    <!-- Pembagian dan pengelompokan dropdown -->
    <!-- 1st row : Customer Data & Segmentation Data -->
    <div class="vx-row mt-3">
      <!-- Customer Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="py-2 h5 font-semibold">Customer Data</span><hr>
        <!-- One time cust -->
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3">
            One Time
          </div>
          <div class="vx-col sm:w-2/6">
            <vs-switch
              color="dark"
              icon-pack="feather"
              vs-icon-on="icon-check-circle"
              vs-icon-off="icon-slash"
              v-model="isOneTime"
              :disabled="this.checkSkuSelected()"
            >
              <span slot="on">YES</span>
              <span slot="off">NO</span>
            </vs-switch>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ship to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedShippTo"
              :options="optionShippTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :internal-search="false"
              :loading="isLoadingShipto"
              :max-height="200"
              :limit="5"
              :disabled="false"
              v-validate="'required'"
              placeholder="Type to search"
              @input="onSelectedShipto"
              @search-change="handlerSearchShipto"
              :custom-label="customLableShippTo"
            />
            <span
            class="text-danger text-sm"
            v-show="true"
            >{{ msgErrorShippTo }}</span>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="oneTimeCust.address_manual" disabled/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Bill to</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedBillTo"
              :options="optionBillTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="true"
              placeholder="Type to search"
              @input="onSelectedBillto"
              :custom-label="customLableBillto"
            />
            <span
            class="text-danger text-sm"
            v-show="isErrorBillTo"
            >{{ msgErrorBillto }}</span>
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="oneTimeCust.address_manual" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div v-if="!this.isOneTime" class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :loading="isLoadingCus"
              :internal-search="false"
              :max-height="200"
              :limit="5"
              :disabled="true"
              placeholder="Type to search"
              @search-change="handlerSearchCustomer"
              :custom-label="customLableCustomer"
              />
              <!-- @input="onSelectedCus" -->
          </div>
          <div v-else class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="selectedCustomer.code" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Name</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="selectedCustomer.name" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Created By</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="createdby.name" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span> Creation Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              :inline="false"
              v-model="createdby.date"
              placeholder="Select Date"
              :disabled="true"
            ></datepicker>
          </div>
        </div>
        <!-- Cust Deposit Fields, for CBD type -->
        <!-- showCustDeposit -->
        <div v-if="showCustDeposit" class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer Deposit</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustDeposit"
              :options="optionCustDeposit"
              :multiple="true"
              :allow-empty="false"
              track-by="ID"
              :max-height="200"
              placeholder="Type to search"
              @input="onSelectedCustDeposit"
              :custom-label="customLableCustDeposit"
            />
          </div>
        </div>
        <!-- Customer One time Form -->
        <div v-if="this.isOneTime" class="vx-row mb-2 mt-4">
          <div class="vx-col w-full">
            <span class="py-2 h5 font-semibold">One Time Customer Data</span>
            <hr>
          </div>
          <div class="vx-col w-full">
            <div class="vx-row my-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Customer Name <span class="text-danger">*</span></label>
                <vs-input class="w-full" v-model="oneTimeCust.name" placeholder="Input name" name="Customer Name"/>
              </div>
            </div>
            <address-component
              :data="oneTimeCust.address"
              @data="setDataAddress"
            ></address-component>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Ship / Bill Address <span class="text-danger">*</span></label>
                <vs-input class="w-full" v-model="oneTimeCust.address_manual" placeholder="Re-input address"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Contact Person <span class="text-danger">*</span></label>
                <vs-input class="w-full" v-model="oneTimeCust.contact_person" placeholder="Input contact person"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">NIK <span class="text-danger">*</span></label>
                <vs-input type="number" class="w-full" v-model="oneTimeCust.nik" placeholder="Input NIK"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <label class="vs-input--label">Phone Number <span class="text-danger">*</span></label>
                <vs-input type="number" class="w-full" v-model="oneTimeCust.phone" placeholder="Input phone number"/>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- <vs-button size="medium" v-on:click="submitCustOnetime" color="primary"> -->
                <vs-button size="medium" v-on:click="handleCustOnetime" color="primary">
                  {{ this.oneTimeCust.id != null ? "Update" : "Confirm" }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- Segmentation Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="py-2 h5 font-semibold">Segmentation Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="200"
              name="territory"
              :limit="5"
              :disabled="false"
              @search-change="handlerSearchTerritory"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Zone</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              v-model="zone.selected"
              :options="zone.option"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
              @search-change="handlerSearchZone"
              @input="onselectedZone"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.code }} {{ props.option.name }}
                  </span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    {{ props.option.code }} {{ props.option.name }}
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Sales Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesChannel.selected"
              :options="salesChannel.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="code"
              label="name"
              :disabled="this.checkSkuSelected()"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Distribution Channel</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="customerCategory.selected"
              :options="customerCategory.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              track-by="ID"
              label="name"
              :disabled="true"
              :custom-label="customLableDistChannel"
            ></multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Sales ID</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="salesPersonal.selected"
              :options="salesPersonal.option"
              :multiple="false"
              :allow-empty="true"
              :max-height="120"
              :limit="3"
              placeholder="Type to search"
              :loading="isLoadingSales"
              track-by="ID"
              label="Name"
              @search-change="handlerSearchSales"
              @input="onselectedSalesman"
              :disabled="this.checkSkuSelected()"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.Name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.Name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Pricing Group</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              v-model="pricingGroup.selected"
              :options="pricingGroup.options"
              placeholder="Type to search"
              track-by="name"
              label="name"
              :max-height="125"
              :disabled="true"
              @input="onselectedPricingGroup"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }} ({{ props.option.code }})
                  </span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    {{ props.option.name }} ({{ props.option.code }})
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <!-- 2nd row : CO Data & Delivery Data -->
    <div class="vx-row">
      <!-- CO Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="pt-2 h5 font-semibold">CO Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>CO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="dataCustomerOrder.code" disabled/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Ref. PO Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="poRefCode"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>CO Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="soType"
              :options="optionSoType"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="4"
              placeholder="Type to search"
              track-by="Code"
              :custom-label="customLableCOType"
              :disabled="this.checkSkuSelected()"
            >
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              name="date"
              :inline="false"
              v-model="dateCo"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
              :disabled="this.checkSkuSelected()"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>PO Date</span>
          </div>
          <div class="vx-col sm:w-7/12 w-full">
            <datepicker
              name="date"
              :inline="false"
              v-model="po_date"
              placeholder="Select Date"
              v-validate="'required'"
              :allow-empty="true"
              :disabledDates="disabledDates"
            ></datepicker>
          </div>
          <div class="vx-col sm:w-1/12 w-full flex items-center">
            <!-- <vs-button type="border" color="success" v-on:click="handleClose">Set null</vs-button> -->
            <vs-button size="small" color="danger" icon-pack="feather" icon="icon-x" @click="setNullPoDate()"/>
          </div>
        </div>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Payment Term</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="paymentTerm.selected"
              :options="paymentTerm.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="false"
              @input="onselectedPaymentTerm_"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <!-- Delivery Data -->
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <span class="pt-2 h5 font-semibold">Delivery Data</span><hr>
        <div class="vx-row my-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Allow Partial</span>
          </div>
          <div class="vx-col vx-row sm:w-2/3 w-full">
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="1" v-model="allowPartial" :disabled="isSampleCO()">
              <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
              <input type="radio" value="0" v-model="allowPartial" :disabled="isSampleCO()">
              <label> No</label>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Delivery Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="deliveryType.selected"
              :options="deliveryType.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="this.checkSkuSelected()"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="vx-row mb-6" v-show="showExpenseType">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Expense Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="expenseType.selected"
              :options="expenseType.option"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="120"
              :limit="3"
              placeholder=" Type to search"
              track-by="id"
              label="name"
            >
              <template slot="singleLabel" slot-scope="dt">
                <span class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="dt">
                <div class="option__desc">
                  <span class="option__title">{{ dt.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Request Delivery Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              :inline="false"
              v-model="rDeliveryDate"
              placeholder="Select Date"
              v-validate="'required'"
              :disabledDates="disabledDates"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full flex">
            <span>Note</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-textarea
              v-model="note"
              :disabled="false"
              height="110px"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- CO Line baru -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <!-- <table class="table table-stripe" style="width:100%"> -->
        <table class="table table-bordered" style="width:100%">
          <thead>
            <tr>
              <th style="vertical-align: middle; width:30%;" rowspan="2">SKU Code</th>
              <th style="vertical-align: middle; width:30%;" rowspan="2" v-if="isExistSkuCombo">Item Combo</th>
              <th style="vertical-align: middle; width:10%;" rowspan="2">HU Label</th>
              <th style="text-align:center; width:15%;" colspan="2">Qty</th>
              <th style="vertical-align: middle; text-align:center;" colspan="4">List Price</th>
              <!-- Hide discount -->
              <th style="vertical-align: middle; text-align:center; max-width:70px;" rowspan="2">Rounding</th>
              <th style="vertical-align: middle; text-align:center;" rowspan="2">Tax</th>
              <th v-if="soType != null && soType.Code == 'ZDSM'"  style="vertical-align: middle; text-align:center;" rowspan="2">Reg. Discount</th>
              <th style="vertical-align: middle; text-align:center;" rowspan="2">Grand Total</th>
              <!-- <th style="vertical-align: middle; text-align:center;" rowspan="2">Promotion</th> -->
              <th style="vertical-align: middle; text-align:center;" rowspan="2">Action</th>
            </tr>
            <tr>
              <th style="text-align:center;">HU</th>
              <th style="text-align:center;">UOM</th>
              <th style="text-align:center;">Unit</th>
              <th style="text-align:center;">Cogs</th>
              <th style="text-align:center;">Price</th>
              <th style="text-align:center;">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <!-- Struktur baru, array-of-object -->
            <template v-for="(dt, index) in table.data">
              <!-- <vs-tr :key="index" v-for="(dt, index) in 3"> -->
              <!-- for start sku not combo -->
              <tr :key="index" v-if="!isExistSkuCombo||(dt.hu_labels.length==0||!dt.hu_labels[0].is_combo&&(dt.data_combo?dt.data_combo.length==0:true))">
                <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  <!-- <div :class="''" :key="index2" v-for="(dt2, index2) in dt.formInputs"> -->
                  <div :class="''">
                    <FormInput
                      :components="dt.formInputs[0]"
                      @onSelected="onselected(dt.formInputs[0].value, index)"
                      @search-change="handlerSearchSelect"
                    />
                  </div>
                </td>
                <td v-if="isExistSkuCombo" style="vertical-align: middle;text-align:center;" :rowspan="dt.list_price.length+dt.rowSpanPlus"> -
                </td>
                <td v-if="dt.hu_labels.length > 0" style="vertical-align: middle;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  <span :key="i" v-for="(dt, i) in dt.hu_labels">
                    {{ dt["unit"] + "." }}
                  </span>
                </td>
                <td v-else style="vertical-align: middle;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  {{ " - " }}
                </td>
                <td style="vertical-align: middle;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  <!-- LAYOUT GOOD -->
                  <div>
                    <vs-row vs-justify="center">
                      <vs-col v-for="(q, ix) in dt.items_qty_hu" :key="ix" vs-type="flex" :vs-w="dt.colWidth" vs-justify="center" vs-align="center">
                        <vs-input v-model="dt.items_qty_hu[ix]" type="text" @change="calc_itemsLineQtyHU(index, ix)"></vs-input>
                      </vs-col>
                    </vs-row>
                  </div>
                </td>
                <td style="vertical-align: middle; padding-right: 25px;" :rowspan="dt.list_price.length+dt.rowSpanPlus">
                  <vs-input-number v-model="dt.items_qty" class="w-full" placeholder="" @input="calc_itemsLineQtyConv(index)"/>
                </td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <!-- Hide discount -->
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0 && soType != null && soType.Code == 'ZDSM'"  style="vertical-align: middle;"></td>
                <td v-if="dt.list_price.length == 0" style="vertical-align: middle;">
                  <div v-if="index == 0">
                    <vx-tooltip text="Add Item">
                      <vs-button
                        size="small"
                        color="green"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="handleAddItem()"
                      />
                    </vx-tooltip>
                    <vx-tooltip v-if="dt.formInputs[0].value.code !== undefined" text="Delete Item">
                      <vs-button
                        size="small"
                        color="red"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click="handleRemoveItem(index)"
                        class="mt-2"
                      />
                    </vx-tooltip>
                  </div>
                  <div v-else style="align-items: center; vertical-align: middle;">
                    <vx-tooltip text="Delete Item">
                      <vs-button
                        size="small"
                        color="red"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click="handleRemoveItem(index)"
                      />
                    </vx-tooltip>
                  </div>
                </td>
              </tr>

              <!-- List Price -->
              <!-- diubah jadi template -->
              <template v-for="(lp, i) in table.data[index].list_price">
                <tr :key="index+'-'+i" :class="dt.qty_hu[i]>0?'table-expend':''" v-if="!isExistSkuCombo||(dt.hu_labels.length==0||!dt.hu_labels[0].is_combo&&(dt.data_combo?dt.data_combo.length==0:true))" >
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;" :rowspan="lp.is_expand?dt.list_price.length:null">
                      {{ dt.qty_hu[i] + ' (' + lp.unit + ')' }} <br>
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(lp.cogs) }} <br>
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ '@' + formatCurrency(lp.price) }} <br>
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.subtotal.price[i]) }} <br>
                  </td>
                  <td style="vertical-align: middle; text-align:center;">
                    <div>
                      <vs-row vs-justify="center">
                        <vs-col vs-justify="center" vs-align="center">
                          <vs-input v-model="lp.rounding" type="text" @input="onInputRounding(index, i)" style="max-width: 75px;"></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                    <!-- {{ "rounding here" }} -->
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.subtotal.tax[i]) }} <br>
                  </td>
                  <!-- Hide discount -->
                  <td v-if="soType != null && soType.Code == 'ZDSM'" v-on:click="expandDetail(index,i)"  class="td vs-table--td" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.subtotal.disc[i]) }} <br>
                  </td>
                  <td class="td vs-table--td" v-on:click="expandDetail(index,i)" style="vertical-align: middle; text-align:right;">
                      {{ formatCurrency(dt.grand_total[i]) }} <br>
                  </td>
                  <td v-if="i == 0" style="vertical-align: middle; text-align:right;" :rowspan="dt.list_price.length+dt.rowSpanPlus-1">
                    <div v-if="index == 0 && i == 0">
                      <vx-tooltip text="Add Item">
                        <vs-button
                          size="small"
                          color="green"
                          icon-pack="feather"
                          icon="icon-plus"
                          @click="handleAddItem()"
                        />
                      </vx-tooltip>
                      <vx-tooltip v-if="dt.formInputs[0].value.code !== undefined" text="Delete Item">
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                          class="mt-2"
                        />
                      </vx-tooltip>
                    </div>
                    <div v-else-if="index != 0 && i == 0" style="align-items: center; vertical-align: middle;">
                      <vx-tooltip text="Delete Item">
                        <vs-button
                        size="small"
                        color="red"
                        icon-pack="feather"
                        icon="icon-trash"
                        @click="handleRemoveItem(index)"
                        />
                      </vx-tooltip>
                    </div>
                    <div v-else style="align-items: center; vertical-align: middle;"></div>
                  </td>
                </tr>
                <tr :key="'expand-head-'+index+'-'+i" v-if="lp.is_expand && (!isExistSkuCombo||(dt.hu_labels.length==0||!dt.hu_labels[0].is_combo&&(dt.data_combo?dt.data_combo.length==0:true)))">
                  <td colspan="6">
                  <!-- expand-head -->
                    <tr>
                      <th>Width<br>
                        @{{ lp.width }}
                      </th>
                      <th>Weight<br>
                        @{{ lp.weight }}
                      </th>
                      <th>
                        Height <br>
                        @{{ lp.height }}
                      </th>
                      <th>Length<br>
                        @{{ lp.length }}
                      </th>
                      <th>Volume<br>
                        @{{ lp.volume }}
                      </th>
                      <th>Net Weight<br>
                        @{{ lp.net_weight }}
                      </th>
                      <th>Price Rule Code</th>
                      <th>Price Rule Valid From</th>
                      <th>Price Rule Valid To</th>
                      <th>Division Code</th>
                      <th>Division Description</th>
                    </tr>
                    <!-- expand-body -->
                    <tr>
                      <td>{{ lp.total_width }}</td>
                      <td>{{ lp.total_weight }}</td>
                      <td>{{ lp.total_height }}</td>
                      <td>{{ lp.total_length }}</td>
                      <td>{{ lp.total_volume }}</td>
                      <td>{{ lp.total_net_weight }}</td>
                      <td>{{ lp.price_rule_code }}</td>
                      <td>{{ lp.price_rule_valid_from }}</td>
                      <td>{{ lp.price_rule_valid_to }}</td>
                      <td>{{ lp.division_code }}</td>
                      <td>{{ lp.division_description }}</td>
                    </tr>
                  </td>
                </tr>
              </template>
              <!-- for end sku not combo -->

              <!-- for start sku combo -->
              <tr :key="index" v-if="isExistSkuCombo&&(dt.hu_labels.length>0&&dt.hu_labels[0].is_combo&&dt.data_combo)">
                <td style="align-item-center; vertical-align: middle; width: 300px; max-width: 400px;" :rowspan="
                  (dt.data_combo?dt.data_combo.length:0)+(dt.rowSpanPlus>0?dt.rowSpanPlus:1)
                  ">
                  <!-- <div :class="''" :key="index2" v-for="(dt2, index2) in dt.formInputs"> -->
                  <div :class="''">
                    <FormInput
                      :components="dt.formInputs[0]"
                      @onSelected="onselected(dt.formInputs[0].value, index)"
                      @search-change="handlerSearchSelect"
                    />
                  </div>
                </td>
              </tr>

              <template v-for="(dtCombo,iCombo) in dt.data_combo">
                <tr :key="index+'-c-'+iCombo" :class="dt.qty_hu[iCombo]>0?'table-expend-combo':''" v-if="isExistSkuCombo&&(dt.hu_labels.length>0&&dt.data_combo&&dt.data_combo.length>0)">
                  <!-- dt.rowSpanPlus -->
                  <td class="combo" style="vertical-align: middle;" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    [{{ dtCombo.ItemCode }}] {{ dtCombo.ItemName }}
                  </td>
                  <td v-if="dt.hu_labels.length > 0" style="vertical-align: middle;" class="combo" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    <span :key="i" v-for="(dt, i) in dt.hu_labels">
                      {{ dt["unit"] + "." }}{{ dtCombo.UnitName }}
                    </span>
                  </td>
                  <td v-else style="vertical-align: middle;" class="combo" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    {{ " - " }}
                  </td>
                  <td style="vertical-align: middle;" class="combo" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    <!-- LAYOUT GOOD -->
                    <div>
                      <vs-row vs-justify="center">
                        <vs-col v-for="(q, ix) in dt.items_qty_hu" :key="ix" vs-type="flex" :vs-w="dt.colWidth" vs-justify="center" vs-align="center">
                          <vs-input v-model="dt.items_qty_hu[ix]" type="text" disabled="true"></vs-input>
                          <vs-input v-model="dt.data_combo[iCombo].total_qty" type="text" disabled="true"></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                  </td>
                  <td style="vertical-align: middle; padding-right: 25px;" class="combo" v-if="iCombo==0" :rowspan="(dt.rowSpanPlus>0?dt.rowSpanPlus:1)+1">
                    <vs-input-number v-model="dt.items_qty" class="w-full" placeholder="" @input="calc_itemsLineQtyConv(index)"/>
                  </td>
                  <td style="vertical-align: middle;" class="combo-hover" v-on:click="expandDetail(index,iCombo)" :rowspan="dt.list_price[iCombo]&&dt.list_price[iCombo].is_expand?2:null">
                    {{ dt.list_price.length > 0?dt.data_combo[iCombo].total_qty + ' (' + dt.list_price[iCombo].unit + ')':'' }}
                  </td>
                  <td style="vertical-align: middle;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.list_price[iCombo].cogs):0 }}
                  </td>
                  <td style="vertical-align: middle;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.list_price[iCombo].price):0 }}
                  </td>
                  <td style="vertical-align: middle;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.price[iCombo]):0 }}
                  </td>

                  <td style="vertical-align: middle;">
                    <div v-if="dt.list_price.length > 0">
                      <vs-row vs-justify="center">
                        <vs-col vs-justify="center" vs-align="center">
                          <vs-input v-model="dt.list_price[iCombo].rounding" type="text" @input="onInputRounding(index, iCombo)" style="max-width: 75px;"></vs-input>
                        </vs-col>
                      </vs-row>
                    </div>
                    <!-- {{ "rounding here" }} -->
                  </td>
                  <td style="vertical-align: middle;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.tax[iCombo]):0 }}
                  </td>
                  <td style="vertical-align: middle;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.subtotal.disc[iCombo]):0 }}
                  </td>
                  <td v-if="soType != null && soType.Code == 'ZDSM'"  style="vertical-align: middle;" class="combo-hover" v-on:click="expandDetail(index,iCombo)">
                    {{ dt.list_price.length > 0?formatCurrency(dt.grand_total[iCombo]):0 }}
                  </td>
                  <td v-if="iCombo==0" style="vertical-align: middle;" :rowspan="(dt.rowSpanPlus>0?dt.rowSpanPlus:1)+1" class="combo-hover">
                    <div v-if="index == 0">
                      <vx-tooltip text="Add Item">
                        <vs-button
                          size="small"
                          color="green"
                          icon-pack="feather"
                          icon="icon-plus"
                          @click="handleAddItem()"
                        />
                      </vx-tooltip>
                      <vx-tooltip v-if="dt.formInputs[0].value.code !== undefined" text="Delete Item">
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                          class="mt-2"
                        />
                      </vx-tooltip>
                    </div>
                    <div v-else style="align-items: center; vertical-align: middle;">
                      <vx-tooltip text="Delete Item">
                        <vs-button
                          size="small"
                          color="red"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="handleRemoveItem(index)"
                        />
                      </vx-tooltip>
                    </div>
                  </td>
                </tr>
                <!-- v-if="dt.list_price[iCombo].is_expand" -->
                <tr :key="index+'-c-expand-'+iCombo" v-if="dt.list_price[iCombo]?dt.list_price[iCombo].is_expand:false">
                  <td colspan="6">
                  <!-- expand-head -->
                    <tr>
                      <th>Width({{ dtCombo.UnitName }})<br>
                        @{{ dt.list_price[iCombo].width }}
                      </th>
                      <th>Weight<br>
                        @{{ dt.list_price[iCombo].weight }}
                      </th>
                      <th>
                        Height <br>
                        @{{ dt.list_price[iCombo].height }}
                      </th>
                      <th>Length<br>
                        @{{ dt.list_price[iCombo].length }}
                      </th>
                      <th>Volume<br>
                        @{{ dt.list_price[iCombo].volume }}
                      </th>
                      <th>Net Weight<br>
                        @{{ dt.list_price[iCombo].net_weight }}
                      </th>
                      <th>Price Rule Code</th>
                      <th>Price Rule Valid From</th>
                      <th>Price Rule Valid To</th>
                      <th>Division Code</th>
                      <th>Division Description</th>
                    </tr>
                    <!-- expand-body -->
                    <tr>
                      <td>{{ dt.list_price[iCombo].total_width }}</td>
                      <td>{{ dt.list_price[iCombo].total_weight }}</td>
                      <td>{{ dt.list_price[iCombo].total_height }}</td>
                      <td>{{ dt.list_price[iCombo].total_length }}</td>
                      <td>{{ dt.list_price[iCombo].total_volume }}</td>
                      <td>{{ dt.list_price[iCombo].total_net_weight }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_code }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_valid_from }}</td>
                      <td>{{ dt.list_price[iCombo].price_rule_valid_to }}</td>
                      <td>{{ dt.list_price[iCombo].division_code }}</td>
                      <td>{{ dt.list_price[iCombo].division_description }}</td>
                    </tr>
                  </td>
                </tr>
              </template>
              <!-- for end sku combo -->

            </template>
            <tr v-if="table.data[0].list_price.length != 0 && this.CalcRespData != null">
              <td colspan="8" style="vertical-align: middle; text-align:right;"> <b>Total</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_order.toFixed(2)) }}</b> </td>
              <!-- Hide discount -->
              <!-- <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_discount.toFixed(2)) }}</b> </td> -->
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_rounding.toFixed(2)) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_tax.toFixed(2)) }}</b> </td>
              <td style="vertical-align: middle; text-align:right;"> <b>{{ formatCurrency(this.CalcRespData.total_billing.toFixed(2)) }}</b> </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="float-left" type="border" color="primary" @click="handleCalculate()">
          Calculate
        </vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import FormInput from "./form_input.vue"
import Datepicker from "vuejs-datepicker";
import AddressComponent from "@/components/master/Address";
export default {
  components :{
    Datepicker,
    FormInput,
    AddressComponent,
  },

  data() {
    const id = this.$route.params.id;
    const username = this.$userLogin.name;
    return {
      baseUrl: "",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      createdby:{
        name:username,
        date:new Date()
      },
      detail: false,
      isErrorBillTo:false,
      msgErrorBillto:"",
      isErrorShippTop:false,
      msgErrorShippTo:"",
      deleteId: null,
      AllOptionTerritory: [],
      optionTerritory: [],
      optionCustomer: [],
      optionBillTo: [],
      optionShippTo: [],
      isLoadingCus:false,
      isLoadingSales:false,
      isLoadingShipto:false,
      countLoadingShipto:0,
      selectedTerritory: {
        id:null
      },
      selectedCustomer: {
        code:"",
        name:""
      },
      selectedBillTo: {
        ID:null,
        customer_id:"",
        code:"",
        address:"",
      },
      selectedShippTo: {
        ID:null,
        customer_id:"",
        code:"",
        address:"",
        search:"",
      },
      searchCust:"",
      searchItem:"",
      searchSales:"",
      dataCustomerOrder:{
        code:null,
      },
      dataCustomerOrderLine:{},
      table: this.tableDefaultState(),
      dateCo:null,
      po_date: null,
      duedate:null,
      note:null,
      rDeliveryDate:null,
      disabledDates: {
          to: new Date(Date.now() - 8640000)
        },
      title:"Form Customer-order",
      id: id,
      allowPartial: 0,
      poRefCode: null,
      isOneTime: false,
      sameAddress: false,
      oneTimeCust: {
        id:null,
        customer:null,
        name:null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          countryID: 0,
          provinceName: "",
          provinceID: 0,
          cityName: "",
          cityID: 0,
          transportZone: "",
          districtName: "",
          districtID: 0,
          subdistrictName: "",
          subdistrictID: 0,
          showWarningAddress: false,
        },
        contact_person:null,
        nik:null,
        phone:null,
        payment_method_id:null,
        tax_id:null,
      },
      pricingGroup: {
        selected: null,
        options: [],
        all_options: [],
      },
      update: false,
      calculated: false,
      optionSoType: [],
      allOptionSoType: [],
      soType: null,
      zone: {
        selected: null,
        option: [],
        all_options: [],
      },
      salesChannel: {
        selected: null,
        option: [],
        all_options: [],
      },
      customerCategory: {
        selected: null,
        option: [],
        all_options: [],
      },
      salesPersonal: {
        selected: null,
        option: [],
        all_options: [],
      },
      paymentTerm: {
        selected: null,
        option: [],
        all_options: [],
      },
      deliveryType: {
        selected: null,
        option: [],
        all_options: [],
      },
      expenseType: {
        selected: {
            id :0,
            name :'',
          },
        option: [
          {
            id :1,
            name :'Principal',
          },
          {
            id :2,
            name :'Distributor',
          }
        ],
      },
      showOTCForm: false,
      CalcRespData: null,
      selectedCustDeposit: [],
      optionCustDeposit: [],
      showCustDeposit: false,
      showExpenseType : false,
      isExistSkuCombo:false,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [
          {
            id:1,
            formInputs:[
              {
                id:1,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                isLoading:false,
                multiple: false,
                allowempty: false,
                value: {},
                optionValue:[],
              },
            ],
            items_hu: '0',
            items_qty: '0',
            qty_hu: [],
            hu_labels: [],
            list_price: [],
            reg_disc: [],
            promotion: [],
            tax: [],
            tax_rate: null,
            subtotal: {
              price: [],
              disc: 0,
              tax: [],
              all_price: 0,
              all_tax: 0,
            },
            colWidth: 0,
          }
        ],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handlerSearchCustomer(search){
      this.searchCust = search
      this.getDataCustomer();
    },
    handlerSearchTerritory(search){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      search = new RegExp(search, "gi")
      this.optionTerritory = this.AllOptionTerritory.filter(v => {
        return v.name.match(search) != null;
      });
    },
    handlerSearchZone(search){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      search = new RegExp(search, "gi")
      this.zone.option = this.zone.all_options.filter(v => {
        return v.name.match(search) != null;
      });
    },
    handlerSearchSales(search){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      this.searchSales = search
      if(search != ""){
        this.getOptionSalesID(false);
      }
    },
    getTerritory() {
      this.$vs.loading();
      this.$http.get("api/v1/master/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.AllOptionTerritory = resp.data.records;
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = null;
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            this.$vs.loading.close();
          }
        });
    },
    getOptionZones() {
      this.zone.option = [];
      this.zone.all_options = [];
      this.$vs.loading();
      this.$http.get("api/v1/master/zone").then((resp) => {
        if (resp.status == "success") {
          this.zone.option = resp.data.records;
          this.zone.all_options = resp.data.records;
          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Zone option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      });
    },
    getOptionSalesChannel() {
      let superadmin, url;
      this.$userLogin.roles.filter(function(item) {
        switch (item.name) {
          case "superadmin":
            url = "/api/v1/sales-channel/list";
            superadmin = true;
            break;
          case "admin":
            url = "/api/v1/sales-channel/list";
            superadmin = false;
            break;
        }
      });

      url = "/api/v1/sales-channel/list";
      this.$http.get(url).then(
        resp => {
          if(resp.code == 200){
            this.salesChannel.option = []
            this.salesChannel.all_options = []
            for(var salesChannel = 0; salesChannel<resp.data.length; salesChannel++){
              this.salesChannel.option.push(resp.data[salesChannel])
              this.salesChannel.all_options.push(resp.data[salesChannel])
            }
            this.salesChannel.selected = null;
          }else{
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Sales Channel option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            // console.log(resp)
          }
        }
      );
    },
    getOptionCustomerCategory() {
      this.$http.get("api/v1/master/customer-category-all").then(
        resp => {
          if(resp.code == 200){
            this.customerCategory.option = []
            this.customerCategory.all_options = []
            for(var customerCategory = 0; customerCategory<resp.data.length;customerCategory++){
              this.customerCategory.option.push(resp.data[customerCategory])
              this.customerCategory.all_options.push(resp.data[customerCategory])
            }
            this.customerCategory.selected = null;
          }else{
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Distribution Channel option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        }
      );
    },
    getOptionSalesID(is_default) {
      let superadmin, territory_id, sales_channel_id, sales_personal_id;
      this.$userLogin.roles.filter(function(item) {
        if(item.Name == "superadmin"){
          superadmin = true;
        }else{
          superadmin = false;
        }
      });
      this.isLoadingSales = true
      territory_id = (this.selectedTerritory != null && this.selectedTerritory.id) ? this.selectedTerritory.id : 0;
      sales_channel_id = this.salesChannel.selected != null ? this.salesChannel.selected.ID : 0;
      if (this.selectedShippTo.sales_personal_id != undefined && this.selectedShippTo.sales_personal_id != 0) {
        sales_personal_id = this.selectedShippTo.sales_personal_id;
      } else {
        sales_personal_id = 0;
      }
      this.$http.get("/api/v1/sales-order/personals",{
          params: {
            search: this.searchSales,
            territory_id: territory_id,
            sales_channel_id: sales_channel_id,
            sales_personal_id: sales_personal_id,
          }
        }).then(
        resp => {
          this.isLoadingSales = false
          if(resp.code == 200){
            this.salesPersonal.option = []
            this.salesPersonal.all_options = []
            let data = resp.data.salesman
            for(var idx = 0;idx<data.length;idx++){
              this.salesPersonal.option.push(data[idx])
              this.salesPersonal.all_options.push(data[idx])
            }
            if (!superadmin) {
              // set default Sales ID jika bukan superadmin
              this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
                return v.ID == this.$userLogin.id;
              });
            }
            if(is_default && sales_personal_id != 0){
              // set default Sales ID saat dipanggil dari selectedCust
              this.salesPersonal.selected = this.salesPersonal.option[0];
              // set sales channel
              this.salesChannel.selected = this.salesChannel.all_options.filter((v) => {
                return v.ID == this.salesPersonal.option[0].SalesChannelId;
              });
              this.salesChannel.selected = this.salesChannel.selected[0];
            }
          } else {
            // console.log(resp)
          }
        }
      );
    },
    getAllDataPricingGroup() {
      this.$vs.loading();
      this.$http.get("api/v1/master/pricing-group", {
        params: {
          order: "name",
          sort: "asc",
        },
      }).then((resp) => {
        if (resp.status == "success") {
          this.pricingGroup.options = resp.data.records;
          this.pricingGroup.all_options = resp.data.records;
          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Pricing Group option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      });
    },
    getOptionPaymentTerm() {
      this.$vs.loading();
      this.$http.get("api/v1/master/payment-term", {
        params: {
          order: 'name',
          sort: 'asc',
        }
      }).then(resp => {
        if (resp.code == 200) {
          // this.paymentTerm.option = resp.data.records;
          this.paymentTerm.all_options = resp.data.records;
          this.paymentTerm.selected = null;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Payment Term option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      });
    },
    getOptionDeliveryType() {
      this.$vs.loading();
      this.$http.get("api/v1/master/delivery-type", {
        params: {
          order: 'id',
          sort: 'asc',
        }
      }).then(resp => {
        if (resp.code == 200) {
          this.deliveryType.option = resp.data.records;
          this.deliveryType.all_options = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Delivery Type option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      });
    },
    getSOTypes(){
      // this.$vs.loading();
      return new Promise((resolve, reject) => {
      // this.$http.get("api/v1/master/sales-order-type", {
        this.$http.get("api/v1/master/sales-order-type", {
          params: {
            order: 'name',
            sort: 'asc',
          }
        }).then(resp => {
            // console.log(resp)
            if (resp.code == 200) {
              this.optionSoType = resp.data.records;
              this.allOptionSoType = resp.data.records;
              resolve(true);
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Failed to get Customer Order Type option",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                reject(false);
            }
          // this.$vs.loading.close();
        });
      });
    },
    onselected(select, i){
      /*
        Dicek dulu, harus isi form dengan benar.
        Case 1
        1. harus pilih data customer dan salesman
        2. piliih data terkait SO (type, date, delivery, dll)
        jika point 1 & 2 sudah terisi, baru boleh pilih sku

        Case 2
        1. harus pilih data customer dan salesman
        2. piliih data terkait SO (type, date, delivery, dll)
        jika sku sudah terpilih, tidak boleh mengubah point 1 & 2.
        user harus ngosongin sku yg terpilih dulu baru bisa ubah point 1 & 2 (kembali ke case 1)
      */

      let check_res = this.checkFormComplete();

      console.log("this.table.data[i]",this.table.data[i]);

      if (!check_res.is_complete) {
        // if form not complete, value item dinolkan, dan etc" nya juga dinolkan
        this.table.data[i].formInputs[0].value = {};
        this.table.data[i].items_qty_hu = [];
        this.table.data[i].items_hu = '0';
        this.table.data[i].items_qty = '0';
        this.table.data[i].hu_labels = [];
        this.table.data[i].list_price = [];
        this.table.data[i].reg_disc = [];
        this.table.data[i].promotion = [];
        this.table.data[i].tax = [];
        this.table.data[i].tax_rate = null;
        this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };

        for (let i = check_res.msg_incomplete.length-1; i > -1; i--) {
          this.$vs.notify({
            title: "Warning",
            text: check_res.msg_incomplete[i],
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }

        this.calculated = false;
        return;
      } else {
        let checkItem, is_duplicate = false;
        // check duplicate items
        if(this.table.data.length > 1){
          this.table.data.forEach((item, j) => {
            checkItem = item.formInputs[0].value;
            if(checkItem.code == select.code && i != j){
              is_duplicate = true;
            }
          });
        }
        // // console.log(currentItem, select)
        if(is_duplicate){
          // if duplicate, value item dinolkan, dan etc" nya juga dinolkan
          this.table.data[i].formInputs[0].value = {};
          this.table.data[i].items_qty_hu = [];
          this.table.data[i].items_hu = '0';
          this.table.data[i].items_qty = '0';
          this.table.data[i].hu_labels = [];
          this.table.data[i].list_price = [];
          this.table.data[i].reg_disc = [];
          this.table.data[i].promotion = [];
          this.table.data[i].tax = [];
          this.table.data[i].tax_rate = null;
          this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
          this.$vs.notify({
            title: "Warning",
            text: "Duplicate item detected!",
            color: "warning",
            position: "top-right"
          });
          this.calculated = false
          return;
        }else{
          this.calculated = false
          this.$http.get("api/v1/item-hu", {
            params: {
              sku_code: select.code,
              co_type: this.soType == null ? "" : this.soType.Code,
              sales_personal_id: this.salesPersonal.selected.ID,
              is_combo:select.is_combo?true:false,
            },
          }).then((resp) => {
            // // console.log(resp)
            if (resp.status == "success") {
              // set column width property
              // console.log("colwidth before",this.table.data[i].colWidth);
              this.table.data[i].colWidth = (12/resp.data.hu.length);
              // console.log("colwidth after",this.table.data[i].colWidth);
              //
              // ======= Add items dan HU =======
              //
              this.table.data[i].hu_labels = resp.data.hu;
              this.table.data[i].tax_rate = resp.data.tax_rate;
              let sumHU = [];
              let data_Combo = []
              for (let i = 0; i < resp.data.hu.length; i++) {
                sumHU.push(0);
                if (resp.data.hu[i].combo_items) {
                  resp.data.hu[i].combo_items.forEach((el)=>{
                    el.total_qty = 0
                    data_Combo.push(el)
                  })
                  this.isExistSkuCombo = true
                }
              }
              this.table.data[i].items_qty_hu = sumHU;
              this.table.data[i].items_hu = sumHU.join('.');
              this.table.data[i].items_qty = 0;
              // reset line items price
              this.table.data[i].list_price = [];
              this.table.data[i].reg_disc = [];
              this.table.data[i].promotion = [];
              this.table.data[i].tax = [];
              this.table.data[i].data_combo = data_Combo;
              // this.table.data[i].tax_rate = null;
              this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
            }
            console.log("this.table.data[i]",this.table.data[i])
          });
        }
        console.log("this.table.data",this.table.data)
      }

    },
    // onSelectedCus(select){

    // },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} - ${name}`;
    },
    customLableBillto({ code, address }) {
      return `${code} - ${address}`;
    },
    customLableShippTo({ code, address }) {
      return `${code} - ${address}`;
    },
    customLableCustDeposit({ Code, DepositValue }){
      return `${Code} - Value : ${this.formatCurrency(DepositValue)}`;
    },
    customLableCOType({ Code, Description }){
      return `${Code} - ${Description}`;
    },
    customLableDistChannel({ name, classification }){
      return `${name} - ${classification}`;
    },
    // onselectedTerritory(Select){

    // },
    // onselectedSalesChannel(){

    // },
    onselectedDistChannel(){
      if(!this.isOneTime){
        this.getDataCustomer();
        // console.log("saya terpanggil 1533")
        this.resetShipBillto();
      }
    },
    onselectedSalesman(){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      this.salesChannel.selected = this.salesChannel.all_options.filter((v) => {
        return v.ID == this.salesPersonal.selected.SalesChannelId;
      });
      this.salesChannel.selected = this.salesChannel.selected[0];
      /*
        1. kalau canvas, maka item/sku nya hanya terbatas pada yang dibawa sales
        2. reset item yang kepilih dulu
        3. get data item nya dikasi param SOType
      */
      if (this.soType != null) {
        if (this.soType.Code == "ZDCV") {
          this.getItem();
          // re empty selected SKU
          this.table.data.forEach((dt,i) => {
            this.table.data[i].formInputs[0].value = {};
            this.table.data[i].items_qty_hu = [];
            this.table.data[i].items_hu = '0';
            this.table.data[i].items_qty = '0';
            this.table.data[i].hu_labels = [];
            this.table.data[i].list_price = [];
            this.table.data[i].reg_disc = [];
            this.table.data[i].promotion = [];
            this.table.data[i].tax = [];
            this.table.data[i].tax_rate = null;
            this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
          });
        }
      }
    },
    onselectedPricingGroup(){
      if(!this.isOneTime){
        this.getDataCustomer();
        // console.log("saya terpanggil 1569")
        this.resetShipBillto();
      }
    },
    onselectedZone(select){
      if(!this.isOneTime){
        this.getDataCustomer();
        this.optionTerritory = this.AllOptionTerritory.filter(v => {
          return v.code == select.territory_code;
        });
        this.selectedTerritory = this.optionTerritory[0];
        this.getOptionSalesID(false);
        // console.log("saya terpanggil 1580")
        this.resetShipBillto();
      }
    },
    onSelectedShipto(select){
      if(!this.isOneTime){
        // console.log(select)
        select.status = select.status.replace("/", "");
        var array_ = JSON.parse(select.status);
        // console.log("array_", array_)
        this.optionTerritory = this.AllOptionTerritory.filter(v => {
          return v.id == select.territory_id;
        });
        this.selectedTerritory = this.optionTerritory[0];

        if (array_.includes("Billing Address")) {
          // sudah dengan billing address, maka auto set billing address juga
          this.selectedBillTo = select;
        } else {
          // only shipping address, maka hit api dan cari dulu Billing address untuk
          // console.log("ShippTo customer_id",this.selectedShippTo.customer_id)
        }

        this.getDataCustomer();
        this.getOptionSalesID(true);

        // console.log("this.selectedBillTo", this.selectedBillTo)
        // console.log("this.selectedShippTo", this.selectedShippTo)
      }
    },
    onSelectedBillto(){
    },
    onSelectedCustDeposit(){
      this.calculated = false;
    },
    onselectedPaymentTerm_(){},
    onselectedPaymentTerm(select){
      // set duedate after paymentterm selected (req deliv + due_invoice_period payment-term)
      let due_inv_period = select.due_invoice_period

      // due date di-hide, maka yang berperan jadi due-date adl rDD
      // let rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
      // let duedate = new Date(rDeliveryDate);
      // duedate.setDate( duedate.getDate() + due_inv_period)
      // this.duedate = duedate;

      let coDate = moment(this.dateCo).format("MM/DD/YYYY");
      let rdd = new Date(coDate);
      rdd.setDate( rdd.getDate() + due_inv_period)
      this.rDeliveryDate = rdd;
    },
    handleCalculate(){
      // console.log(this.table.data);
      let check_res = this.checkFormComplete();

      // console.log(check_res);

      let is_complete = check_res.is_complete;
      let msg_incomplete = check_res.msg_incomplete;


      // Jika CO Canvas, maka dicek juga untuk stock yang dibawa oleh sales, apakah jumlahnya cukup?
      if(this.soType != null && this.soType.Code == "ZDCV"){
        for (let i = 0; i < this.table.data.length; i++) {
          let _items = this.table.data[i].formInputs[0].value;
          let items_qty_hu = this.table.data[i].items_qty_hu;
          for (let j = 0; j < this.table.data[i].hu_labels.length; j++) {
            // if( parseInt(qty_per_HU[j]) > 0 ){
            let stokSkuSales = this.table.data[i].hu_labels[j].stock;
            if( parseInt(items_qty_hu[j]) > stokSkuSales ){
              is_complete = false;
              msg_incomplete.push("Insufficent stock for item " + _items.sku_name + "["+this.table.data[i].hu_labels[j].unit+"] Stock remaining : " + stokSkuSales)
            }
          }
        }
      }

      // for (let i = 0; i < this.table.data[i].hu_labels.length; j++) {
      //   // if( parseInt(qty_per_HU[j]) > 0 ){
      //   let stokSkuSales = this.table.data[i].hu_labels[j].stock;
      //   if( parseInt(items_qty_hu[j]) > stokSkuSales ){
      //     is_complete = false;
      //     msg_incomplete.push("Insufficent stock for item " + _items.sku_name + "["+this.table.data[i].hu_labels[j].unit+"] Stock remaining : " + stokSkuSales)
      //   }
      // }

      // Batas pengecekan Canvas

      // check if jumlah UOM tiap item > 0 | if 0 maka return tidak bisa calculate
      for (let i = 0; i < this.table.data.length; i++) {
        let itmCheck = this.table.data[i];

        if (parseInt(itmCheck.items_qty) == 0 ) {
          is_complete = false;
          console.log("itmCheck.items_qty, ",itmCheck.items_qty)
          msg_incomplete.push("Please add qty order for item [" + itmCheck.formInputs[0].value.code + "]" + itmCheck.formInputs[0].value.sku_name);
        }
      }

      if(!is_complete){
        for (let i = check_res.msg_incomplete.length-1; i > -1; i--) {
          this.$vs.notify({
            title: "Warning",
            text: check_res.msg_incomplete[i],
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }
        // msg_incomplete.forEach(msg => {
        //   this.$vs.notify({
        //     title: "Warning",
        //     text: msg,
        //     color: "danger",
        //     position: "top-right",
        //     iconPack: "feather",
        //     icon: "icon-alert-circle"
        //   });
        // });
        this.calculated = false;
        return;
      }

      let params = {
        sales_channel_code: this.salesChannel.selected.code,
        territory_code: this.selectedTerritory.code,
        company_code: this.$store.state.user.currentUser.company_code,
        company_id: this.$store.state.user.currentUser.company_id,
        customer_code: this.isOneTime ? this.oneTimeCust.customer.code : this.selectedCustomer.code,
        pricing_group_id: this.pricingGroup.selected == null ? 0 : this.pricingGroup.selected.ID,
        promo_code: [],
        tax_rate: 0,
        subtotal_charge: 0,
        items:[],
        so_type: this.soType.Code,
        date :this.dateCo ? moment(String(this.dateCo)).format("YYYY-MM-DD") : null
      };

      for (let i = 0; i < this.table.data.length; i++) {
        console.log(this.table.data[i])
        let _items = this.table.data[i].formInputs[0].value;
        let items_qty_hu = this.table.data[i].items_qty_hu;
        // let qty_per_HU = this.table.data[i].items_hu.split('.');
        for (let j = 0; j < this.table.data[i].hu_labels.length; j++) {
          // if( parseInt(qty_per_HU[j]) > 0 ){
            let is_combo = false
            is_combo = this.table.data[i].hu_labels[j].is_combo
            console.log("is_combo",is_combo)
          if (is_combo) {
            let _combo_items = []
            _combo_items = this.table.data[i].data_combo
            _combo_items.forEach((el)=>{
              let temp_item = {
                item_code : el.ItemCode,
                item_name : el.ItemName,
                item_uom : el.UnitName,
                item_quantity_request : 0,
                item_quantity_adjusted : parseInt(el.total_qty),
                item_price : 0,
                is_combo: true,
                rounding : 0,
              }
              params.items.push(temp_item)
            })

          }else if( parseInt(items_qty_hu[j]) > 0 ){
            let temp_item = {
              item_code : _items.code,
              item_name : _items.sku_name,
              item_uom : this.table.data[i].hu_labels[j].unit,
              item_quantity_request : 0,
              item_quantity_adjusted : parseInt(items_qty_hu[j]),
              item_price : 0,
              is_combo:false,
              rounding : this.table.data[i].list_price.length == 0 ? 0 : parseFloat(this.table.data[i].list_price[j].rounding),
            }
            params.items.push(temp_item)
          }
        }
      }

      // console.log('NEW params',params)

      this.$vs.loading();
      this.$http
      .post("/api/v3/sales-order/calculate", params)
      .then(resp => {
        // // console.log(resp);
        if (resp.code == 200) {

          var calc_resp = resp.data;
          var is_available_price = true;
          for (var i = 0; i < calc_resp.pricing.length; i++) {

            if(calc_resp.pricing[i].sub_total <= 0 && calc_resp.pricing[i].sub_total_engine <= 0) {
              this.$vs.loading.close();
            }

            if(calc_resp.pricing[i].error_message != "") {
              this.$vs.notify({
                title: "Error",
                text: calc_resp.pricing[i].error_message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle"
              });
              this.$vs.notify({
                title: "Error",
                text: "Please set price for item : " + calc_resp.pricing[i].code,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle"
              });
              is_available_price = false;
            }
          }

          if (is_available_price) {
            // Loops price/pricing per SO Line, per HU
            for (let i = 0; i < this.table.data.length; i++) {
              this.table.data[i].list_price = [];
              this.table.data[i].reg_disc = [];
              this.table.data[i].tax = [];
              this.table.data[i].items_qty_hu = [];
              this.table.data[i].qty_hu = [];
              this.table.data[i].subtotal.price = [];
              this.table.data[i].subtotal.tax = [];
              this.table.data[i].subtotal.tax_rate = [];
              this.table.data[i].subtotal.disc = [];
              this.table.data[i].subtotal.gross_up = [];
              this.table.data[i].grand_total = [];
              this.table.data[i].rowSpanPlus = 1;

              let sku = this.table.data[i];
              let warn_item = this.table.data[i].formInputs[0].value
              let sku_item_unit_ids = []
              let calc_item_unit_ids = []

              this.table.data[i].hu_labels.forEach(v => {
                if (!v.is_combo) {
                  console.log("sku_item_unit_ids is combo",v.is_combo);
                  sku_item_unit_ids.push( parseInt(v.item_unit_id));
                }
              });

              calc_resp.pricing.forEach(calc => {
                if (!calc.is_combo) {
                  console.log("calc_item_unit_ids is combo",calc.is_combo);
                  calc_item_unit_ids.push( parseInt(calc.item_unit_id));
                }
              });

              // console.log("sku_item_unit_ids", sku_item_unit_ids)
              // console.log("calc_item_unit_ids", calc_item_unit_ids)

              let all_tax = 0, grand_total = 0, st_price = 0, st_disc = 0, st_gross_up = 0, all_gross_up = 0, temp_listprice = null;
              this.table.data[i].hu_labels.forEach((val,j) => {
                if (val.is_combo) {
                  this.table.data[i].items_qty_hu.push( this.table.data[i].items_qty )
                }
                calc_resp.pricing.forEach(calc=>{
                  let combo_items = []
                  combo_items = this.table.data[i].data_combo
                  if (calc.is_combo) {
                    combo_items.forEach((elm)=>{
                      if (elm.ItemCode==calc.code) {
                        console.log(elm.ItemCode,"==",calc.code);
                        if(calc.unit == elm.UnitName){
                          console.log(calc.unit,"==",elm.UnitName);
                            // calc_item_unit_ids.push( parseInt(calc.item_unit_id));
                            temp_listprice = {
                              price: calc.price ? calc.price.toFixed(2) : 0 ,
                              item_name: calc.name,
                              unit: calc.unit,
                              rounding: calc.rounding,
                              gross_up: calc.gross_up,
                              cogs:calc.cogs,
                              height:calc.height,
                              width:calc.width,
                              length:calc.length,
                              volume:calc.volume,
                              weight:calc.weight,
                              price_rule_code:calc.price_rule_code,
                              price_rule_id:calc.price_rule_id,
                              price_rule_valid_to:moment(calc.price_rule_valid_to).format("YYYY-MM-DD"),
                              price_rule_valid_from:moment(calc.price_rule_valid_from).format("YYYY-MM-DD"),
                              division_code:calc.division_code,
                              division_description:calc.division_description,
                              net_weight:calc.net_weight,
                              total_height:calc.total_height,
                              total_weight:calc.total_weight,
                              total_width:calc.total_width,
                              total_volume:calc.total_volume,
                              total_length:calc.total_length,
                              is_expand:false,
                              total_net_weight:calc.total_net_weight,
                            }

                            console.log("temp_listprice=>",temp_listprice);

                            this.table.data[i].list_price.push( temp_listprice );
                            this.table.data[i].reg_disc.push( calc.discountable.toFixed(2) );
                            this.table.data[i].qty_hu.push( calc.qty );

                            st_price += calc.sub_total;
                            // st_disc += calc.sub_total_discountable;
                            st_gross_up += calc.gross_up;

                            all_tax += calc.tax;
                            all_gross_up += calc.gross_up;
                            this.table.data[i].subtotal.tax.push( calc.tax.toFixed(2) );
                            this.table.data[i].subtotal.tax_rate.push( calc.tax_rate );

                            this.table.data[i].subtotal.price.push( calc.sub_total.toFixed(2) );

                            st_disc = (this.soType != null && this.soType.Code == "ZDSM") ? calc.sub_total_discountable + calc.tax : calc.discountable;
                            this.table.data[i].subtotal.disc.push( st_disc.toFixed(2) ); // ini subtotal discount (disc * qty)
                            grand_total = (this.soType != null && this.soType.Code == "ZDSM") ? calc.sub_total - calc.sub_total_discountable : calc.sub_total - calc.discountable + calc.tax;
                            this.table.data[i].grand_total.push( (grand_total).toFixed(2) );

                            // if (calc.tax > 0) {
                            //   resp.data.total_tax += calc.tax;
                            //   resp.data.total_discount += calc.tax;
                            // }

                          }
                      }
                    })
                  }
                  if (!val.is_combo) {
                    console.log("val.sku_code <==>",val.sku_code);
                    // calc_resp.pricing.forEach((calc)=>{
                      if(val.sku_code == calc.code&&!calc.is_combo){
                        if(calc.unit == this.table.data[i].hu_labels[j].unit && calc_item_unit_ids.includes( parseInt(this.table.data[i].hu_labels[j].item_unit_id))){
                          // calc_item_unit_ids.push( parseInt(calc.item_unit_id));
                          temp_listprice = {
                            price: calc.price ? calc.price.toFixed(2) : 0 ,
                            item_name: calc.name,
                            unit: calc.unit,
                            rounding: calc.rounding,
                            gross_up: calc.gross_up,
                            cogs:calc.cogs,
                            height:calc.height,
                            width:calc.width,
                            length:calc.length,
                            volume:calc.volume,
                            weight:calc.weight,
                            price_rule_code:calc.price_rule_code,
                            price_rule_id:calc.price_rule_id,
                            price_rule_valid_to:moment(calc.price_rule_valid_to).format("YYYY-MM-DD"),
                            price_rule_valid_from:moment(calc.price_rule_valid_from).format("YYYY-MM-DD"),
                            division_code:calc.division_code,
                            division_description:calc.division_description,
                            net_weight:calc.net_weight,
                            total_height:calc.total_height,
                            total_weight:calc.total_weight,
                            total_width:calc.total_width,
                            total_volume:calc.total_volume,
                            total_length:calc.total_length,
                            is_expand:false,
                            total_net_weight:calc.total_net_weight,
                          }

                          this.table.data[i].list_price.push( temp_listprice );
                          this.table.data[i].reg_disc.push( calc.discountable.toFixed(2) );
                          this.table.data[i].qty_hu.push( calc.qty );
                          this.table.data[i].items_qty_hu.push( calc.qty );

                          st_price += calc.sub_total;
                          // st_disc += calc.sub_total_discountable;
                          st_gross_up += calc.gross_up;

                          all_tax += calc.tax;
                          all_gross_up += calc.gross_up;
                          this.table.data[i].subtotal.tax.push( calc.tax.toFixed(2) );
                          this.table.data[i].subtotal.tax_rate.push( calc.tax_rate );

                          this.table.data[i].subtotal.price.push( calc.sub_total.toFixed(2) );

                          st_disc = (this.soType != null && this.soType.Code == "ZDSM") ? calc.sub_total_discountable + calc.tax : calc.discountable;
                          this.table.data[i].subtotal.disc.push( st_disc.toFixed(2) ); // ini subtotal discount (disc * qty)
                          grand_total = (this.soType != null && this.soType.Code == "ZDSM") ? calc.sub_total - calc.sub_total_discountable : calc.sub_total - calc.discountable + calc.tax;
                          this.table.data[i].grand_total.push( (grand_total).toFixed(2) );

                          // if (calc.tax > 0) {
                          //   resp.data.total_tax += calc.tax;
                          //   resp.data.total_discount += calc.tax;
                          // }

                        } else if ( !calc_item_unit_ids.includes( parseInt(this.table.data[i].hu_labels[j].item_unit_id)) ) {
                            temp_listprice = {
                              price: calc.price ? calc.price.toFixed(2) : 0 ,
                              item_name: calc.name,
                              unit: calc.unit,
                              rounding: 0,
                              gross_up: 0,
                            }

                            this.table.data[i].list_price.push( temp_listprice );
                            this.table.data[i].reg_disc.push( (0).toFixed(2) );
                            this.table.data[i].qty_hu.push( 0 );
                            this.table.data[i].items_qty_hu.push( 0 );

                            st_price += 0;
                            // st_disc += calc.sub_total_discountable;
                            st_gross_up += 0;

                            all_tax += 0;
                            all_gross_up += 0;
                            this.table.data[i].subtotal.tax.push( (0).toFixed(2) );
                            this.table.data[i].subtotal.tax_rate.push( calc.tax_rate );

                            this.table.data[i].subtotal.price.push( (0).toFixed(2) );

                            // push grandtotal
                            st_disc = 0;
                            this.table.data[i].subtotal.disc.push( st_disc.toFixed(2) ); // ini subtotal discount (disc * qty)
                            grand_total = 0;
                            this.table.data[i].grand_total.push( (grand_total).toFixed(2) );

                        } else {

                        }
                      }
                    // })
                  }
                })
              });

              this.table.data[i].subtotal.gross_up.push(st_gross_up.toFixed(2))
              // console.log(st_price)

              if(st_price <= 0){
                  this.calculated = false;
                  this.$vs.notify({
                    title: "Error",
                    text: "Please add qty or set price for item : " + warn_item.sku_name,
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-alert-circle"
                  });
              }

              this.table.data[i].subtotal.all_price = st_price.toFixed(2);
              this.table.data[i].subtotal.all_tax = all_tax.toFixed(2);
              this.table.data[i].subtotal.all_gross_up = all_gross_up.toFixed(2);
              console.log("this.table.data[i]",this.table.data[i]);
            }
            this.calculated = true;
          }

          this.$vs.loading.close();

          // if(calc_resp.total_billing <= 0) {
            // alert("ada yang 0")
            // this.calculated = false;
            // this.$vs.loading.close();
          // }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to calculate Sales Order",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.calculated = false;
          this.$vs.loading.close();
        }
        if(this.calculated==true) {
          // console.log(this.table.data)
          this.CalcRespData = resp.data;
          this.$vs.notify({
            title: "Success",
            text: "",
            color: "success",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
        // // console.log(this.table.data)
        this.$vs.loading.close();
      });

    },
    checkDeposit(){
      let total_deposit = 0, cond = null;
      this.selectedCustDeposit.forEach(cd => {
        total_deposit += cd.DepositValue;
      });

      if (parseInt(this.CalcRespData.total_billing) > parseInt(total_deposit)) {
        let exceed = parseInt(this.CalcRespData.total_billing) - parseInt(total_deposit);
        cond = {
          status : false,
          exceed : exceed,
        }
      } else {
        cond = {
          status : true,
          exceed : 0,
        }
      }
      return cond;
    },
    handlerSearchSelect(searching,comp) {
      if (this.soType == null) {
        this.$vs.notify({
          title: "Warning",
          text: "Please select CO type !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      this.getItem(searching,this.table.data[comp.parentId-1].formInputs)
    },
    handleAddItem() {
      let check_res = this.checkFormComplete();

      if(!check_res.is_complete){
        for (let i = check_res.msg_incomplete.length-1; i > -1; i--) {
          this.$vs.notify({
            title: "Warning",
            text: check_res.msg_incomplete[i],
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        }

        this.calculated = false;
        return;
      }

      // // console.log(this.table)
      let i_new = this.table.data.length
      let empty_holder = {
        id:i_new+1,
        formInputs:[
          {
            id:1,
            parentId:i_new+1,
            disabled: false,
            validate: "required",
            name: "sku_code",
            placeholder: "Sku Code",
            head:"Sku Code",
            type: "multiselect",
            multiple: false,
            allowempty: false,
            value: {},
            optionValue:[],
          },
        ],
        items_qty_hu: [],
        items_hu: '0',
        items_qty: '0',
        hu_labels: [],
        list_price: [],
        reg_disc: [],
        promotion: [],
        tax: [],
        tax_rate: null,
        subtotal: {
          price: [],
          disc: 0,
          tax: [],
          all_price: 0,
          all_tax: 0,
        },
      };
      this.table.data.push(empty_holder);
      this.getItem(null,this.table.data[i_new].formInputs)
      this.calculated = false;
    },
    handleRemoveItem(index) {
      if (index == 0) {
        this.table.data[index].formInputs[0].value = {};
        this.table.data[index].items_qty_hu = [];
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = '0';
        this.table.data[index].hu_labels = [];
        this.table.data[index].list_price = [];
        this.table.data[index].reg_disc = [];
        this.table.data[index].promotion = [];
        this.table.data[index].tax = [];
        this.table.data[index].tax_rate = null;
        this.table.data[index].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
      } else {
        this.table.data = this.table.data.filter((v, i) => {
          return i != index;
        });
      }
      this.calculated = false;
    },
    handleClose() {
      // back to list
      this.$router.push({ name: "customer-order" });
    },
    showOTForm(){
      this.showOTCForm = true;
    },
    handleCloseOt() {
      this.showOTCForm = false;
    },
    getDataCustomer() {
      // console.log("this.selectedShippTo", this.selectedShippTo);

      if (this.selectedShippTo.ID != null) {
        let url = "/api/v1/master/customer/" + this.selectedShippTo.customer_id;
        this.isLoadingCus = true
        this.$http.get(url).then((resp) => {
            this.isLoadingCus =false
            if (resp.code == 200) {
              if (resp.data != null) {
                this.optionCustomer = [resp.data];
                this.selectedCustomer = resp.data;
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = { code:"", name:"" };
              }
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to get customer. " + resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle"
              });
            }
            // console.log("this.selectedCustomer", this.selectedCustomer);
          });
      }
    },
    getItem(search,comp) {
      let idx =0
      if (comp) {
        idx = comp[0].parentId-1;
        this.table.data[idx].formInputs[0].isLoading =true
      }else{
        this.table.data.forEach((dt,i) => {
          this.table.data[i].formInputs[0].isLoading =true
        });
      }
      let searchItem =""
      if(search){
        searchItem = search
      }
      let params = {
        limit: 40,
        offset: 0,
        search: searchItem,
        co_type_code: this.soType != null ? this.soType.Code : "",
        sales_personal_id: this.soType != null ? (this.soType.Code == "ZDCV" && this.salesPersonal.selected != null) ? this.salesPersonal.selected.ID : 0 : 0,
      }
      this.$http
      .get("api/v1/get-item-available", { params: params,})
      .then((resp) => {
        // // console.log(resp)
        this.table.data[idx].formInputs[0].isLoading =false
        if (resp.code == 200) {
          let data =[]
          if (resp.data.records != null) {
            resp.data.records.forEach(dt=>{
              data.push(
                {
                  id:dt.item_id,
                  code:dt.sku_code,
                  text:'[' + dt.sku_code + '] ' + dt.item_name,
                  sku_name:dt.item_name,
                  is_combo:dt.is_combo
                }
              )
            })
          }
          if (comp) {
            this.table.data[idx].formInputs[0].optionValue = data
          }else{
            this.table.data.forEach((dt,i) => {
              this.table.data[i].formInputs[0].optionValue  = data
              this.table.data[i].formInputs[0].isLoading    = false
            });
          }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Item option",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          // console.log(resp);
        }
      });
    },
    getOnetimeCustomer() {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-onetime").then(resp => {
        if (resp.code == 200) {
          this.selectedCustomer.code = '[ ' + resp.data.records.code + ' ] ' + resp.data.records.name;
          this.selectedCustomer.name = "";
          this.oneTimeCust.customer = resp.data.records;
            // filter dist channel
            this.customerCategory.selected = this.customerCategory.all_options.filter((v) => {
              return v.ID == resp.data.records.customer_category_id;
            });
            this.customerCategory.selected = this.customerCategory.selected[0]
            // filter pricing group
            this.pricingGroup.selected = this.pricingGroup.all_options.filter((v) => {
              return v.ID == resp.data.records.pricing_group_id;
            });
            this.pricingGroup.selected = this.pricingGroup.selected[0]
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Customer",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      });
    },
    handleCustOnetime(){
      /*
        Save temporary UI data Onetime Customer
        Check apakah form terisi dengan benar? jika ya :
        1. set column field Customer Name
        2. set column field Bill to
        3. Set column field Ship to
      */

      let form_empty = true;
      // Buat save customer onetime disini, ke tabel baru
      form_empty = (this.oneTimeCust.name == null || this.oneTimeCust.name == "") && form_empty;
      form_empty = (this.oneTimeCust.contact_person == null || this.oneTimeCust.contact_person == "") && form_empty;
      form_empty = (this.oneTimeCust.nik == null || this.oneTimeCust.nik == "") && form_empty;
      form_empty = (this.oneTimeCust.phone == null || this.oneTimeCust.phone == "") && form_empty;

      if(form_empty){
        this.$vs.notify({
          title: "Error",
          text: "Please fill onetime customer form correctly !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return;
      }

      this.selectedCustomer.name = this.oneTimeCust.name;
      this.$vs.notify({
        color: "success",
        title: "Success",
        text: "Set customer data success",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-check-circle",
      });
      window.scrollTo(0,0);
      // this.handleCloseOt();

    },
    submitCustOnetime(){
      return new Promise((resolve, reject) => {
        let form_empty;
        // Buat save customer onetime disini, ke tabel baru
        form_empty = (this.oneTimeCust.name == null || this.oneTimeCust.name == "");
        form_empty = (this.oneTimeCust.contact_person == null || this.oneTimeCust.contact_person == "");
        form_empty = (this.oneTimeCust.nik == null || this.oneTimeCust.nik == "");
        form_empty = (this.oneTimeCust.phone == null || this.oneTimeCust.phone == "");

        if(form_empty){
          this.$vs.notify({
            title: "Error",
            text: "Please fill form correctly !",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          return;
        }
        // this.$vs.loading();
        let params, url;
        // // console.log(this.oneTimeCust);
        url = this.oneTimeCust.id != null ?
          "/api/v1/sales-order/onetime_cust/" + this.oneTimeCust.id :
          "/api/v1/sales-order/onetime_cust/store";
        params = {
          // Default by Customer - ONETIME CUSTOMER
          type: this.oneTimeCust.customer.type,
          customer_id: this.oneTimeCust.customer.ID,
          payment_method_id: this.oneTimeCust.customer.payment_method_id,

          // Default init value, updated after SO created
          payment_term_id: this.oneTimeCust.customer.payment_term_id,

          // Value by input
          name: this.oneTimeCust.name,
          country: this.oneTimeCust.address.countryName,
          province: this.oneTimeCust.address.provinceName,
          city: this.oneTimeCust.address.cityName,
          district: this.oneTimeCust.address.districtName,
          sub_district: this.oneTimeCust.address.subdistrictName,
          address: this.oneTimeCust.address_manual,
          postal_code: this.oneTimeCust.address.postalCode,
          contact_person: this.oneTimeCust.contact_person,
          nik: this.oneTimeCust.nik,
          phone: this.oneTimeCust.phone,
          tax_id: this.oneTimeCust.tax_id,
        };

        // // console.log('params', params)
        this.$http.post(url, params).then(resp => {
          // // console.log(url, resp);
          if (resp.code == 200) {
            /*
              Sukses
              1. set column field Customer Name
              2. set column field Bill to
              3. Set column field Ship to
            */
            this.oneTimeCust.id = this.oneTimeCust.id == null ? resp.data.ID : this.oneTimeCust.id;
            this.selectedCustomer.name = this.oneTimeCust.name;

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            resolve({
              line: "2332",
              resp: resp,
              status: "success"
            })
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            reject({
              line: "2346",
              resp: resp,
              status: "failed"
            })
          }
          // this.$vs.loading.close();
        });
      });
    },
    resetOTC_data(){
      let oneTimeCust = {
        id:null,
        customer:null,
        name:null,
        address_manual: null,
        address: {
          address: "",
          postalCode: "",
          countryName: "",
          provinceName: "",
          cityName: "",
          districtName: "",
          subdistrictName: "",
        },
        contact_person:null,
        nik:null,
        phone:null,
        payment_method_id:null,
        tax_id:null,
      };
      this.oneTimeCust = oneTimeCust;
    },
    setDataAddress(val) {
      // console.log("val",val)
      // console.log("oneTimeCust.address", this.oneTimeCust.address)
      this.oneTimeCust.address = val;
    },
    calc_itemsLineQty(index){
      // // console.log('index', 1658)
      var uomConv, sumHU, sumUOM = 0;
      sumHU = this.table.data[index].items_hu.split(".");
      uomConv = this.table.data[index].hu_labels;

      if(uomConv.length == 0){
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = '0';
        return
      } else if (sumHU.length != uomConv.length){
        // this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Invalid input !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }else{
        for (let i = 0; i < sumHU.length; i++) {
          sumUOM += parseInt(sumHU[i] * parseInt(uomConv[i].amount_uom));
          this.table.data[index].items_qty = sumUOM;
        }
        this.calculated = false;
      }
    },
    calc_itemsLineQtyConv(index){
      // // console.log('index', 1682)
      var uomConv, sumHU, amount_uom, sumUOM = 0;
      let dataCombo = []
      let val_input = this.table.data[index].items_qty;

      if (this.isExistSkuCombo) {
        dataCombo = this.table.data[index].data_combo
        dataCombo.forEach((el,i)=>{
          if (this.table.data[index].data_combo[i].Quantity) {
            var qtySum = this.table.data[index].data_combo[i].Quantity*parseInt(val_input)
            if (qtySum>0) {
              this.table.data[index].data_combo[i].total_qty = qtySum
            }else{
              this.table.data[index].data_combo[i].total_qty = 0
            }
          }
        })
      }
      sumHU = [];
      uomConv = this.table.data[index].hu_labels;
      sumUOM = parseInt(val_input);

      if(uomConv.length == 0){
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = 0;
        return
      }

      for (let i = 0; i < uomConv.length; i++) {
        amount_uom = parseInt(uomConv[i].amount_uom);
        if((sumUOM / amount_uom) > 0){
          sumHU.push(parseInt(sumUOM / amount_uom));
          sumUOM = sumUOM % amount_uom;
        }else{
          sumHU.push(0);
        }
      }
      this.calculated = false;
      this.table.data[index].items_hu = sumHU.join('.');
      this.table.data[index].items_qty_hu = sumHU;
    },
    calc_itemsLineQtyHU(index, hu_index){
      // console.log(hu_index)
      // console.log('this.table.data[index].items_qty_hu', this.table.data[index].items_qty_hu);
      // console.log('this.table.data[index].items_qty_hu[hu_index]', this.table.data[index].items_qty_hu[hu_index]);
      var qtyHU, uomConv, sumUOM = 0, lowest_hu;
      uomConv = this.table.data[index].hu_labels;
      qtyHU = this.table.data[index].items_qty_hu;
      lowest_hu = hu_index == (uomConv.length-1) ? true : false;
      if(qtyHU[hu_index] == undefined){
        this.table.data[index].items_qty = 0;
        this.$vs.notify({
          title: "Error",
          text: "Select item first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        this.table.data[index].items_hu = '0';
        this.table.data[index].items_qty = '0';
        return
      } else if( isNaN( qtyHU[hu_index]) || qtyHU[hu_index] == '' ) {
        // console.log('Then')
        // console.log('this.table.data[index].items_qty_hu', this.table.data[index].items_qty_hu);
        // console.log('this.table.data[index].items_qty_hu[hu_index]', this.table.data[index].items_qty_hu[hu_index]);
        this.table.data[index].items_hu = '0';
        // this.table.data[index].items_qty = '0';
        this.table.data[index].items_qty_hu[hu_index] = 0;
        this.$vs.notify({
          title: "Error",
          text: "Invalid input !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return
      }else{
        if(lowest_hu && uomConv.length != 1){
          qtyHU[hu_index] = parseInt(qtyHU[hu_index], 10);
          qtyHU[hu_index-1] = parseInt(qtyHU[hu_index-1], 10);
          // cek jika yang berubaha adalah lowest HU, dan jumlah yang diinput lebih dari amount_uom HU atasnya, maka ditambah ke UOM atasnya, dan value diset jadi mod nya
          if(qtyHU[hu_index] >= parseInt(uomConv[hu_index-1].amount_uom)){
            let divRes, modRes;
            divRes = qtyHU[hu_index] / parseInt(uomConv[hu_index-1].amount_uom);
            modRes = qtyHU[hu_index] % parseInt(uomConv[hu_index-1].amount_uom);
            // tambah uom atasnya
            qtyHU[hu_index-1] += divRes;
            // ubah input HU sekarang
            qtyHU[hu_index] = modRes;
          }
        }
        for (let i = 0; i < uomConv.length; i++) {
          qtyHU[i] = parseInt(qtyHU[i], 10);
          sumUOM += parseInt(qtyHU[i] * parseInt(uomConv[i].amount_uom));
        }
        this.table.data[index].items_hu = qtyHU.join('.');
        this.table.data[index].items_qty = sumUOM;
        this.calculated = false;
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    resetShipBillto(){
      // console.log("saya terpanggil")
      this.optionBillTo = [];
      this.optionShippTo = [];
      this.selectedBillTo = {
        ID:null,
        customer_id:"",
        code:"",
        address:"",
      }
      this.selectedShippTo = {
        ID:null,
        customer_id:"",
        code:"",
        address:"",
      }
    },
    handlerSearchShipto(search){
      // console.log("Masuk rene neh lho. search nya? ")
      // console.log("search nya? empty kah ?" , (search != ""))
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }

      this.selectedShippTo.search = search
      if(search != ""){
        let params = {
          territory_id: this.selectedTerritory != null ? (this.selectedTerritory.id != undefined ? this.selectedTerritory.id : 0) : 0,
          search: search,
          length: 40,
        }
        this.getAllAddress(params);
      }else{
        this.isLoadingShipto = false
      }
    },
    getAllAddress(params){
      // console.log("this.selectedTerritory", this.selectedTerritory);
      // console.log("params", params);
      const tempSearch = params.search
      this.isLoadingShipto = true
      var url2 = "/api/v1/master/customer-address/ship-to"
      this.$http.get(url2, {params: params,}).then((resp) => {
        // console.log(resp)
        if (tempSearch==this.selectedShippTo.search) {
          this.isLoadingShipto = false
        }
        if (resp.code == 200&&tempSearch==this.selectedShippTo.search) {
          if (resp.data.records != null) {
            this.optionShippTo = resp.data.records;
          } else {
            this.optionShippTo = [];
          }
        } else {
          // console.log(resp)
        }
      });
    },
    onInputRounding(index, i){
      // console.log("Disini 2509", index, i)
      this.calculated = false;
    },
    getCustDeposit(cust_id) {
      this.$vs.loading();
      this.$http.get("api/v1/cash-bank/customer-deposit/" + cust_id).then((resp) => {
        // console.log(resp);
        if (resp.status == "success") {
          if(resp.data.cashBank.length > 0){
            this.optionCustDeposit = resp.data.cashBank;
          } else {
            this.$vs.notify({
              title: "Warning",
              text: "No deposit found ! Please add or create one first !",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          }
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setNullPoDate(){
      this.po_date = null;
    },
    checkFormComplete(){
      let is_complete = true, msg_incomplete = [];

      if(this.selectedShippTo.ID == null && !this.isOneTime){
        is_complete = false;
        msg_incomplete.push("Please select shipp to address !")
      } else {
        if(this.selectedCustomer == {} || this.selectedCustomer == null || this.selectedCustomer.code == "" || this.selectedCustomer.code == null){
          is_complete = false;
          msg_incomplete.push("Please select customer !")
        }

        if(this.selectedBillTo.ID == null && !this.isOneTime){
          is_complete = false;
          msg_incomplete.push("Please select bill to address !")
        }

        if(this.selectedTerritory == null){
          is_complete = false;
          msg_incomplete.push("Please select territory !")
        }

        if(this.zone.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select zone !")
        }

        if(this.salesChannel.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select sales channel !")
        }

        // // console.log(this.salesPersonal)
        if(this.salesPersonal.selected == null || this.salesPersonal.selected.length == 0){
          is_complete = false;
          msg_incomplete.push("Please select salesman !")
        }

        if(this.customerCategory.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select distribution channel !")
        }

        if(this.pricingGroup.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select pricing group !")
        }

        if(this.paymentTerm.selected == null){
          is_complete = false;
          msg_incomplete.push("Please select payment term !")
        }

        if(this.soType == null){
          is_complete = false;
          msg_incomplete.push("Please select CO type !")
        }

        if (this.deliveryType.selected == null) {
          is_complete = false;
          msg_incomplete.push("Please select delivery type !")
        }

        let complete_item = true;
        this.table.data.forEach(td => {
          if(td.formInputs[0].value.code == undefined){
            complete_item = false;
          }
        });

        if(!complete_item){
          is_complete = false;
          msg_incomplete.push("Please select item !")
        }

      }

      if(this.oneTimeCust.name == null && this.isOneTime){
        is_complete = false;
        msg_incomplete.push("Please input onetime customer name!")
      }

      if(this.oneTimeCust.address_manual == null && this.isOneTime){
        is_complete = false;
        msg_incomplete.push("Please input onetime customer address!")
      }

      return {
        is_complete: is_complete,
        msg_incomplete: msg_incomplete,
      }
    },
    checkSkuSelected(){
      let complete_item = false;
      this.table.data.forEach(td => {
        if(td.formInputs[0].value.code != undefined){
          complete_item = true;
        }
      });

      return complete_item;
    },
    isSampleCO(){
      if (this.soType != null) {
        if (this.soType.Code == "ZDSM") { this.allowPartial = 0; return true; }else{ return false; }
      } else {
        return false;
      }
    },
    expandDetail(i1,i2){
      console.log(i1,i2)
      if (this.table.data.length>0&&this.table.data[i1].list_price.length>0) {
        this.table.data[i1].list_price[i2].is_expand = !this.table.data[i1].list_price[i2].is_expand
        if (this.table.data[i1].list_price[i2].is_expand) {
          this.table.data[i1].rowSpanPlus++
        }else if(this.table.data[i1].rowSpanPlus>1){
          this.table.data[i1].rowSpanPlus--
        }
      }
    }
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {
    selectedTerritory: function () {
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }

      if(!this.isOneTime && this.selectedShippTo.ID == null){
        this.searchSales = "";
        this.getDataCustomer();
        this.getOptionSalesID(false);
        this.resetShipBillto();
      }

      this.zone.option = this.zone.all_options.filter((v) => {
        return v.territory_code == this.selectedTerritory.code;
      });
      this.zone.selected = this.zone.option[0];
    },
    selectedCustomer: function () {
      let select = this.selectedCustomer;
      if (select != null && select.code != "") {
        // this.optionBillTo   = select.customer_address
        // this.optionShippTo  = select.customer_address

        let territory = (this.selectedTerritory != null && this.selectedTerritory.id) ? this.selectedTerritory : null;

        // // console.log(this.selectedCustomer);
        // filter dist channel
        this.customerCategory.selected = this.customerCategory.all_options.filter((v) => {
          return v.ID == select.customer_category_id;
        });
        this.customerCategory.selected = this.customerCategory.selected[0]
        // filter pricing group
        this.pricingGroup.selected = this.pricingGroup.options.filter((v) => {
          return v.ID == select.pricing_group_id;
        });
        this.pricingGroup.selected = this.pricingGroup.selected[0]
        // filter payment term
        this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
          return v.id == select.payment_term_id;
        });
        this.paymentTerm.selected = this.paymentTerm.selected[0];
        this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
          return v.due_invoice_period <= this.paymentTerm.selected.due_invoice_period;
        });
        // // console.log("this.selectedCustomer", this.selectedCustomer)
        if (this.soType != null && this.soType.Code == "ZDCBD") {
          this.getCustDeposit(select.id);
          this.showCustDeposit = true;
        } else {
          this.showCustDeposit = false;
        }
      }

    },
    dateCo: function() {
      // convert m/d/y only
      let today, dateCo, rDeliveryDate, tempRdd;
      today = moment(new Date()).format("MM/DD/YYYY");
      today = new Date(today);
      if(this.dateCo != null) {
        dateCo = moment(this.dateCo).format("MM/DD/YYYY");
        dateCo = new Date(dateCo);
        if(dateCo < today) {
          this.dateCo = today;
          this.$vs.notify({
            title: "Warning",
            text: "CO date cannot backdated",
            color: "warning",
            position: "top-right"
          });
        }
        if(this.rDeliveryDate != null) {
          rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
          rDeliveryDate = new Date(rDeliveryDate);

          tempRdd = new Date();
          tempRdd.setDate( this.dateCo.getDate() + 2)

          if(rDeliveryDate <= this.dateCo){
            // rDeliveryDate.setDate( this.dateCo.getDate() + 1)
            rDeliveryDate.setDate( this.dateCo.getDate() + 2) //default RDD H+2 dari dateCO
            this.rDeliveryDate = rDeliveryDate;
          }
        }
      }
    },
    rDeliveryDate: function() {
      // convert m/d/y only
      // reqDelivDate minimal today+1 (besok) atau dateCo + 1 day
      let today, dateCo, rDeliveryDate, duedate, limit;
      today = moment(new Date()).format("MM/DD/YYYY");
      today = new Date(today);

      if(this.rDeliveryDate != null) {
        rDeliveryDate = moment(this.rDeliveryDate).format("MM/DD/YYYY");
        rDeliveryDate = new Date(rDeliveryDate);
        if(this.dateCo != null) {
          dateCo = moment(this.dateCo).format("MM/DD/YYYY");
          dateCo = new Date(dateCo);
          limit = dateCo;
          limit.setDate( limit.getDate() + 1)
          if(rDeliveryDate < limit){
            this.rDeliveryDate = limit;
            this.$vs.notify({
              title: "Warning",
              text: "Request delivery date must greater than CO date",
              color: "warning",
              position: "top-right"
            });
          }
        }
        if(rDeliveryDate < today) {
          this.rDeliveryDate = limit;
          this.$vs.notify({
            title: "Warning",
            text: "Request delivery date cannot backdated",
            color: "warning",
            position: "top-right"
          });
        }
      }
    },
    isOneTime: function() {
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        this.isOneTime = !this.isOneTime;
        return;
      }

      if(!this.isOneTime){
        // Reset All
        // reset shipto
        this.selectedShippTo = {
          ID:null,
          customer_id:"",
          code:"",
          address:"",
        }

        // reset billto
        this.selectedBillTo = {
          ID:null,
          customer_id:"",
          code:"",
          address:"",
        }

        // reset custData
        this.selectedCustomer.id = null;
        this.selectedCustomer.name = "";
        this.selectedCustomer.code = "";

        // reset Payment Term and Deliv Type
        this.paymentTerm.option = [];
        this.paymentTerm.selected = null;
        this.deliveryType.option = this.deliveryType.all_options;
        this.deliveryType.selected = null;

        // reset CO Type
        this.optionSoType = [];
        this.optionSoType = this.allOptionSoType
        this.soType = null;

        // reset OneTimeCustomer Data
        this.resetOTC_data();
        this.showOTCForm = false;
      }else{
        // Get onetimeCust master data
        this.showOTCForm = true;
        this.getOnetimeCustomer();

        // Restart territory sampai pricing group, show all entry/field
        this.optionTerritory = this.AllOptionTerritory;
        this.selectedTerritory = null;

        this.zone.option = [];
        this.zone.selected = null;

        this.salesChannel.option = this.salesChannel.all_options;
        this.salesChannel.selected = null;

        this.salesPersonal.option = this.salesPersonal.all_options;
        this.salesPersonal.selected = null;

        // pembatasan Payment Term || if onetime, hanya boleh D0000
        this.paymentTerm.option = [];
        this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
          if(v.name == "D000"){
            return v
          }
        });
        this.paymentTerm.selected = this.paymentTerm.option[0];

        // pembatasan Delivery Type || if onetime, hanya boleh LCO
        this.deliveryType.option = [];
        this.deliveryType.option = this.deliveryType.all_options.filter((v) => {
          if(v.name == "LCO"){
            return v
          }
        });
        this.deliveryType.selected = this.deliveryType.option[0];

        // pembatasan CO Type
        // if onetime, Type nya juga harus onetime
        this.optionSoType = [];
        this.optionSoType = this.allOptionSoType.filter((v) => {
          if(v.Code == "ZDOT"){
            return v
          }
        });
        this.soType = this.optionSoType[0];
      }
    },
    soType: function() {
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      this.calculated = false;
      this.selectedCustDeposit  = [];
      this.optionCustDeposit    = [];
      console.log("this.selectedCustomer", this.selectedCustomer)
      if (this.selectedCustomer.ID && !this.isOneTime) {

        let defaultD000 = ["ZDCV", "ZDCO", "ZDCBD", "ZDSM"]
        let onlyD000 = ["ZDCO", "ZDSM", "ZDCV", "ZDCBD"]

        // Default payment term,
        // In BRD
        // i.	Payment term yang bisa dipilih adalah payment term customer dan payment term < default payment term customer.
        let cust_pt_id = this.selectedCustomer.payment_term_id
        let cust_pt = this.paymentTerm.all_options.filter((v) => {
          return v.id == cust_pt_id;
        });
        cust_pt = cust_pt[0];
        this.paymentTerm.option = this.paymentTerm.all_options.filter((v) => {
          return v.due_invoice_period <= cust_pt.due_invoice_period;
        });

        this.showCustDeposit = false;
        /*
          1. kalau canvas, maka item/sku nya hanya terbatas pada yang dibawa sales
          2. reset item yang kepilih dulu
          3. get data item nya dikasi param SOType
        */
        if (this.soType.Code == "ZDCV") {
          // console.log("this.salesPersonal", this.salesPersonal);
          // console.log("this.soType", this.soType);
          if (this.salesPersonal.selected == null || this.salesPersonal.selected.length == 0) {
            this.soType = null;
            this.$vs.notify({
              title: "Warning",
              text: "Please select salesman first !",
              color: "warning",
              position: "top-right"
            });
            return;
          } else {
            this.getItem();
            // re empty selected SKU
            this.table.data.forEach((dt,i) => {
              this.table.data[i].formInputs[0].value = {};
              this.table.data[i].items_qty_hu = [];
              this.table.data[i].items_hu = '0';
              this.table.data[i].items_qty = '0';
              this.table.data[i].hu_labels = [];
              this.table.data[i].list_price = [];
              this.table.data[i].reg_disc = [];
              this.table.data[i].promotion = [];
              this.table.data[i].tax = [];
              this.table.data[i].tax_rate = null;
              this.table.data[i].subtotal = { price: [], disc: 0, tax: [], all_price: 0, all_tax: 0, };
            });
          }
        }

        if (this.soType.Code == "ZDOT") {
          this.soType = null;
          this.$vs.notify({
            title: "Warning",
            text: "Please activate One Time switch first !",
            color: "warning",
            position: "top-right"
          });
        }

        if (this.soType != null && this.soType.Code == "ZDCBD") {
          this.getCustDeposit(this.selectedCustomer.id);
          this.showCustDeposit = true;
        }

        if ( defaultD000.includes(this.soType.Code) ) {
          // filter payment term
          this.paymentTerm.selected = this.paymentTerm.all_options.filter((v) => {
            return v.name == "D000";
          });

          if (onlyD000.includes(this.soType.Code)) {
            // lock D000
            this.paymentTerm.option = this.paymentTerm.selected;
          }

          this.paymentTerm.selected = this.paymentTerm.selected[0];
        }

        if (this.soType.Code == "ZDTO") {
          // can't choose COD payment term
          this.paymentTerm.option = this.paymentTerm.option.filter((v) => {
            return v.name != "D000";
          });
          this.paymentTerm.selected = this.paymentTerm.option[0];
        }

        if (this.soType.Code == "ZDBB") {
          // filter delivery type, default FCO
          this.deliveryType.selected = this.deliveryType.all_options.filter((v) => {
            if(v.name == "FCO"){
              return v
            }
          });
          this.deliveryType.selected = this.deliveryType.selected[0];
        }
        if (this.soType.Code == "ZDSM") {
          // show expense type
          this.showExpenseType = true

        } else {
          // hide expense type
          this.showExpenseType = false
          // reset selected expense type
          this.expenseType.selected = { id :0, name :'', };
        }
      } else {
        if (this.soType != null && !this.isOneTime) {
          this.soType = null;
          this.$vs.notify({
            title: "Warning",
            text: "Please select customer first !",
            color: "warning",
            position: "top-right"
          });
          return;
        }
      }
    },
    "salesChannel.selected": function (){
      if (this.checkSkuSelected()) {
        this.$vs.notify({
          title: "Warning",
          text: "Please clear the SKU section first !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
        return;
      }
      if (this.salesPersonal.selected == null) {
        this.searchSales = "";
        this.getOptionSalesID(false);
      }
    },
  },
  mounted() {
    this.dateCo = new Date();
    console.log("this.$userLogin", this.$userLogin)

    // Default reqDelivDate today + 1 day
    this.rDeliveryDate = new Date();
    // this.rDeliveryDate.setDate( this.rDeliveryDate.getDate() + 1)
    this.rDeliveryDate.setDate( this.rDeliveryDate.getDate() + 2) // default rdd H+2 from today or dateCO
    this.duedate = this.rDeliveryDate;

    this.getTerritory();
    this.getOptionZones();
    this.getOptionSalesChannel();
    this.getOptionCustomerCategory();
    // this.getOptionSalesID();
    this.getAllDataPricingGroup();
    this.getOptionPaymentTerm();
    this.getOptionDeliveryType();
    this.getItem();
    this.getSOTypes();
    // this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--thead{
  z-index:-1 !important;
  display: none;
}
.table-expend:hover,.table-expend-combo:hover td.combo-hover{
    background: #e9e9e9;
    cursor: pointer;
}
.table-expend:active,.table-expend-combo td.combo-hover:active{
    background: #e7e5e5;
}
</style>
